import { create } from "jss";
import { ThemeProvider } from "styled-components/macro";

import { StyledEngineProvider } from "@mui/styled-engine-sc";
import StylesProvider from "@mui/styles/StylesProvider";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { jssPreset } from "@mui/styles";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import createTheme from "./theme";
import useTheme from "./hooks/theme";
import { config } from "./config";
import Layout from "./layout";
import SignUp from "./components/sign-up";

import "./i18n";
const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

function App() {
  const { theme } = useTheme();

  return (
    <GoogleReCaptchaProvider reCaptchaKey={config.REACT_APP_RECAPTCHA_SITE_KEY}>
      <StylesProvider jss={jss}>
        <StyledEngineProvider injectFirst>
          <MuiThemeProvider theme={createTheme(theme)}>
            <ThemeProvider theme={createTheme(theme)}>
              <Layout>
                <SignUp />
              </Layout>
            </ThemeProvider>
          </MuiThemeProvider>
        </StyledEngineProvider>
      </StylesProvider>
    </GoogleReCaptchaProvider>
  );
}

export default App;
