import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en_US: {
    translation: {
      "Review and check out": "Review and check out",
      "Let's get started": "Let's get started",
      "Sign in": "Sign in",
      username:
        "You'll use your username to log in to your Organogram admin account and create your business email address.",
      Next: "Next",
      Previous: "Previous",
      Success: "Setup Complete! Well done!!",
      Review: "You provided these so far:",
      "Number of employees": "Number of employees including you",
      Domain: "What's your business domain name",
      DomainInfo:
        "Enter your business domain name. You'll use it for custom email addresses, like info@example.com. We'll guide you through verifying that your business owns the domain later.",
      DomainType: "e.g. example.com",
      Profile: "Enter your profile information",
      "Enter your profile information": "Enter your profile information",
      "How you'll sign in": "How you'll sign in",
      "Please enter a valid username, e.g (username@example.com)":
        "Please enter a valid username, e.g (username@example.com)",
      "Please enter a valid phone number": "Please enter a valid phone number",
      "Please pick the size range of your organization":
        "Please pick the size range of your organization",
      "Please enter a valid domain, e.g (example.com)":
        "Please enter a valid domain, e.g (example.com)",
      "Your domain is required.": "Your domain is required.",
      "Enter a valid email address": "Enter a valid email address",
      Required: "Required",
      "Organization domain": "Organization domain",
      "Organization size": "Organization size",
      "Current email address": "Current email address",
      "Last name": "Last name",
      "Current email address*": "Current email address*",
      "Last name*": "Last name*",
      "First name": "First name",
      "Organization name": "Organization name",
      Password: "Password",
      "Organization phone": "Organization phone",
      "Organization Number": "Organization Phone Number",
    },
  },
  en_Uk: {
    translation: {
      "Let's get started": "Let's get started",
      "Review and check out": "Review and check out",
      "Sign in": "Sign in",
      username:
        "You'll use your username to log in to your Organogram admin account and create your business email address.",
      Next: "Next",
      Previous: "Previous",
      Success: "Setup Complete! Well done!!",
      Review: "You provided these thus far:",
      "Number of employees": "Number of employees including yourself",
      Domain: "What's your company domain name",
      DomainInfo:
        "Enter your business domain name. You'll use it for custom email addresses, such as info@example.com. We'll take you through the process of verifying that your business owns the domain later.",
      DomainType: "e.g. example.com",
      Profile: "Enter your profile details",
      "Enter your profile information": "Enter your profile information",
      "How you'll sign in": "How you'll sign in",
      "Please enter a valid username, e.g (username@example.com)":
        "Please enter a valid username, e.g (username@example.com)",
      "Please enter a valid phone number": "Please enter a valid phone number",
      "Please pick the size range of your organization":
        "Please pick the size range of your organization",
      "Please enter a valid domain, e.g (example.com)":
        "Please enter a valid domain, e.g (example.com)",
      "Your domain is required.": "Your domain is required.",
      "Enter a valid email address": "Enter a valid email address",
      Required: "Required",
      "Organization domain": "Organisation domain",
      "Organization size": "Organisation size",
      "Current email address": "Current email address",
      "Last name": "Last name",
      "Current email address*": "Current email address*",
      "Last name*": "Last name*",
      "First name": "First name",
      "Organization name": "Organisation name",
      Password: "Password",
      "Organization phone": "Organization phone",
      "Organization Number": "Organisation Phone Number",
    },
  },
  fr: {
    translation: {
      "Review and check out": "Passer en revue et finaliser",

      "Let's get started": "Commençons",
      "Sign in": "Se connecter",
      username:
        "Vous utiliserez votre nom d'utilisateur pour vous connecter à votre compte administrateur Organogram et créer votre adresse e-mail professionnelle.",
      Next: "Suivant",
      Previous: "Précédent",
      Success: "Configuration terminée ! Félicitations !",
      Review: "Vous avez fourni les éléments suivants jusqu'à présent :",
      "Number of employees": "Nombre d'employés, y compris vous-même",
      Domain: "Quel est le nom de domaine de votre entreprise",
      DomainInfo:
        "Saisissez le nom de domaine de votre entreprise. Vous l'utiliserez pour les adresses e-mail personnalisées, telles que info@example.com. Nous vous guiderons pour vérifier que votre entreprise possède le domaine ultérieurement.",
      DomainType: "par ex. example.com",
      Profile: "Saisissez vos informations de profil",
      "Enter your profile information": "Saisissez vos informations de profil",
      "How you'll sign in": "Comment vous vous connecterez",
      "Please enter a valid username, e.g (username@example.com)":
        "Veuillez saisir un nom d'utilisateur valide, par ex. (username@example.com)",
      "Please enter a valid phone number":
        "Veuillez entrer un numéro de téléphone valide",
      "Please pick the size range of your organization":
        "Veuillez sélectionner la plage de taille de votre organisation",
      "Please enter a valid domain, e.g (example.com)":
        "Veuillez entrer un domaine valide, par ex. (example.com)",
      "Your domain is required.": "Votre domaine est requis.",
      "Enter a valid email address":
        "Veuillez saisir une adresse e-mail valide",
      Required: "Requis",
      "Organization domain": "Domaine de l'organisation",
      "Organization size": "Taille de l'organisation",
      "Current email address": "Adresse e-mail actuelle",
      "Last name": "Nom de famille",
      "Current email address*": "Adresse e-mail actuelle*",
      "Last name*": "Nom de famille*",
      "First name": "Prénom",
      "Organization name": "Nom de l'organisation",
      Password: "Mot de passe",
      "Organization phone": "Téléphone de l'organisation",
      "Organization Number": "Numéro de téléphone de l'organisation",
    },
  },
  de: {
    translation: {
      "Review and check out": "Überprüfen und abschließen",
      "Let's get started": "Lass uns starten",
      "Sign in": "Anmelden",
      username:
        "Sie verwenden Ihren Benutzernamen, um sich bei Ihrem Organogram-Admin-Konto anzumelden und Ihre geschäftliche E-Mail-Adresse zu erstellen.",
      Next: "Weiter",
      Previous: "Zurück",
      Success: "Einrichtung abgeschlossen! Gut gemacht!",
      Review: "Bisher wurden folgende Informationen bereitgestellt:",
      "Number of employees": "Anzahl der Mitarbeiter, einschließlich Ihnen",
      Domain: "Wie lautet die Domäne Ihres Unternehmens",
      DomainInfo:
        "Geben Sie den Domänennamen Ihres Unternehmens ein. Sie werden ihn für individuelle E-Mail-Adressen wie info@example.com verwenden. Wir werden Sie später durch den Prozess der Überprüfung führen, dass Ihr Unternehmen die Domäne besitzt.",
      DomainType: "z. B. example.com",
      Profile: "Geben Sie Ihre Profildetails ein",
      "Enter your profile information":
        "Geben Sie Ihre Profilinformationen ein",
      "How you'll sign in": "Wie Sie sich anmelden werden",
      "Please enter a valid username, e.g (username@example.com)":
        "Bitte geben Sie einen gültigen Benutzernamen ein, z. B. (username@example.com)",
      "Please enter a valid phone number":
        "Bitte geben Sie eine gültige Telefonnummer ein",
      "Please pick the size range of your organization":
        "Bitte wählen Sie den Größenbereich Ihrer Organisation aus",
      "Please enter a valid domain, e.g (example.com)":
        "Bitte geben Sie eine gültige Domäne ein, z. B. (example.com)",
      "Your domain is required.": "Ihre Domäne ist erforderlich.",
      "Enter a valid email address":
        "Geben Sie eine gültige E-Mail-Adresse ein",
      Required: "Erforderlich",
      "Organization domain": "Organisationsdomäne",
      "Organization size": "Organisationsgröße",
      "Current email address": "Aktuelle E-Mail-Adresse",
      "Last name": "Nachname",
      "Current email address*": "Aktuelle E-Mail-Adresse*",
      "Last name*": "Nachname*",
      "First name": "Vorname",
      "Organization name": "Organisationsname",
      Password: "Passwort",
      "Organization phone": "Organisationstelefon",
      "Organization Number": "Organisations-Telefonnummer",
    },
  },
  nl: {
    translation: {
      "Review and check out": "Controleren en afrekenen",
      "Let's get started": "Laten we beginnen",
      "Sign in": "Inloggen",
      username:
        "U gebruikt uw gebruikersnaam om in te loggen op uw Organogram-beheerdersaccount en om uw zakelijke e-mailadres aan te maken.",
      Next: "Volgende",
      Previous: "Vorige",
      Success: "Installatie voltooid! Goed gedaan!",
      Review: "Tot nu toe heeft u het volgende verstrekt:",
      "Number of employees": "Aantal medewerkers, inclusief uzelf",
      Domain: "Wat is de domeinnaam van uw bedrijf",
      DomainInfo:
        "Voer de domeinnaam van uw bedrijf in. U zult deze gebruiken voor aangepaste e-mailadressen, zoals info@example.com. We zullen u later begeleiden bij het verifiëren dat uw bedrijf het domein bezit.",
      DomainType: "bijv. example.com",
      Profile: "Voer uw profielgegevens in",
      "Enter your profile information": "Voer uw profielinformatie in",
      "How you'll sign in": "Hoe u zich zult aanmelden",
      "Please enter a valid username, e.g (username@example.com)":
        "Voer alstublieft een geldige gebruikersnaam in, bijv. (username@example.com)",
      "Please enter a valid phone number":
        "Voer alstublieft een geldig telefoonnummer in",
      "Please pick the size range of your organization":
        "Kies alstublieft de grootte van uw organisatie",
      "Please enter a valid domain, e.g (example.com)":
        "Voer alstublieft een geldige domeinnaam in, bijv. (example.com)",
      "Your domain is required.": "Uw domein is verplicht.",
      "Enter a valid email address": "Voer een geldig e-mailadres in",
      Required: "Verplicht",
      "Organization domain": "Organisatiedomein",
      "Organization size": "Organisatiegrootte",
      "Current email address*": "Huidig e-mailadres*",
      "Last name*": "Achternaam*",
      "Current email address": "Huidig e-mailadres",
      "Last name": "Achternaam",
      "First name": "Voornaam",
      "Organization name": "Organisatienaam",
      Password: "Wachtwoord",
      "Organization phone": "Organisatietelefoon",
      "Organization Number": "Organisatietelefoonnummer",
    },
  },
};

i18n

  .use(LanguageDetector)

  .use(initReactI18next)

  .init({
    debug: false,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources,
  });

export default i18n;
