import React, { useState } from "react";
import styled, { css } from "styled-components/macro";
import { Link } from "react-router-dom";

import { green, grey, indigo } from "@mui/material/colors";
import { Palette as PaletteIcon } from "@mui/icons-material";
import { Box, Button, Drawer, Fab, Grid, Typography } from "@mui/material";
import { THEMES } from "../../constants";
import useTheme from "../../hooks/theme";

const ThemeButton = styled.div`
  cursor: pointer;
  background: ${(props) => props.theme.palette.background.paper};
  height: 80px;
  border-radius: 0.3rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.825rem;
  position: relative;
  border: 1px solid
    ${(props) =>
      !props.active
        ? props.theme.palette.action.selected
        : props.theme.palette.action.active};
`;

const ThemeButtonInner = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 0 0 1px ${(props) => props.theme.palette.action.selected};
  position: relative;

  ${(props) =>
    props.selectedTheme === THEMES.DEFAULT &&
    css`
      background: linear-gradient(-45deg, #23303f 50%, ${grey[100]} 0);
    `}
  ${(props) =>
    props.selectedTheme === THEMES.DARK &&
    css`
      background: #23303f;
    `}
  ${(props) =>
    props.selectedTheme === THEMES.LIGHT &&
    css`
      background: ${grey[100]};
    `}
  ${(props) =>
    props.selectedTheme === THEMES.BLUE &&
    css`
      background: linear-gradient(-45deg, #4782da 50%, ${grey[100]} 0);
    `}
  ${(props) =>
    props.selectedTheme === THEMES.GREEN &&
    css`
      background: linear-gradient(-45deg, ${green[500]} 50%, ${grey[100]} 0);
    `}
  ${(props) =>
    props.selectedTheme === THEMES.INDIGO &&
    css`
      background: linear-gradient(-45deg, ${indigo[500]} 50%, ${grey[100]} 0);
    `}
`;

const ThemeTitle = styled(Typography)`
  text-align: center;
`;

const FabContainer = styled.div`
  position: fixed;
  right: ${(props) => props.theme.spacing(8)};
  bottom: ${(props) => props.theme.spacing(8)};
  z-index: 1;
`;

const Wrapper = styled.div`
  width: 258px;
  overflow-x: hidden;
`;

function Theme({ title, themeVariant }) {
  const { theme, setTheme } = useTheme();

  return (
    <Grid item xs={6}>
      <ThemeButton
        active={themeVariant === theme}
        onClick={() => setTheme(themeVariant)}
        aria-label="theme button"
      >
        <ThemeButtonInner selectedTheme={themeVariant} />
      </ThemeButton>
      <ThemeTitle variant="subtitle2" gutterBottom>
        {title}
      </ThemeTitle>
    </Grid>
  );
}

function Themes() {
  return (
    <Wrapper>
      <Box px={4} my={3}>
        <Grid container spacing={3}>
          <Theme title="Dark" themeVariant={THEMES.DARK} />
          <Theme title="Blue" themeVariant={THEMES.BLUE} />
          <Theme title="Green" themeVariant={THEMES.GREEN} />
          <Theme title="Indigo" themeVariant={THEMES.INDIGO} />
        </Grid>
      </Box>

      <Box my={3} mx={4}>
        <Button
          component={Link}
          to="/"
          variant="outlined"
          size="large"
          target="_blank"
          fullWidth={true}
        >
          Documentation
        </Button>
      </Box>
      <Box my={3} mx={4}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          target="_blank"
          fullWidth={true}
          aria-label="title"
        >
          Organogram
        </Button>
      </Box>
    </Wrapper>
  );
}

function Settings() {
  const [state, setState] = useState({
    isOpen: false,
  });

  const toggleDrawer = (open) => () => {
    setState({ ...state, isOpen: open });
  };

  return (
    <React.Fragment>
      <FabContainer>
        <Fab color="primary" aria-label="Edit" onClick={toggleDrawer(true)}>
          <PaletteIcon />
        </Fab>
      </FabContainer>
      <Drawer anchor="right" open={state.isOpen} onClose={toggleDrawer(false)}>
        <Themes />
      </Drawer>
    </React.Fragment>
  );
}

export default Settings;
