import React, { useState, useEffect, lazy, Suspense } from "react";
import {
  useLocation,
  Routes,
  Route,
  NavLink,
  useNavigate,
} from "react-router-dom";
import styled from "styled-components/macro";

import { ListItemButton, AppBar, CircularProgress } from "@mui/material";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { config } from "../../config";
import Toolbar from "@mui/material/Toolbar";
import NavbarLanguagesDropdown from "../translation";

const Brand = styled(ListItemButton)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const BrandIcon = styled(Logo)`
  margin-right: ${(props) => props.theme.spacing(1)};
  color: ${(props) => props.theme.sidebar.header.brand.color};
  fill: ${(props) => props.theme.sidebar.header.brand.color};
  width: 25px;
  height: 25px;
`;
const Wrapper = styled.div`
  margin-top: 50px;
  padding: ${(props) => props.theme.spacing(10)};
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
  max-width: 450px;
`;

export const ButtonContainer = styled.div`
  & .previous-button {
    margin-right: 20px;
  }
`;

export const FormContainer = styled.div`
  & .textfield {
    margin-bottom: 20px;
  }
`;

const OrganizationProfile = lazy(() => import("./OrganizationProfile"));
const AdminProfile = lazy(() => import("./AdminProfile"));
const OrganizationDomain = lazy(() => import("./Domain"));
const Success = lazy(() => import("./Success"));
const UserName = lazy(() => import("./UserName"));
const Review = lazy(() => import("./Review"));

const SignUp = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [form_data, setFormData] = useState(() => ({
    org_name: "",
    org_domain: "",
    org_phone: "",
    org_size: "",
    password: "",
    username: "",
    user_last_name: "",
    user_first_name: "",
    user_alternate_email: "",
    sku: "",
    source: "",
  }));

  useEffect(() => {
    const query_sku = new URLSearchParams(location.search).get("sku");
    const query_source = new URLSearchParams(location.search).get("source");
    if (!query_sku && !query_source) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        sku: config.sku,
        source: config.source,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        sku: query_sku,
        source: query_source,
      }));
    }
  }, [location.search]);

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    if (form_data) {
      window.addEventListener("beforeunload", unloadCallback);
      navigate("/");
      return () => window.removeEventListener("beforeunload", unloadCallback);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <AppBar>
        <Toolbar disableGutters>
          <Brand component={NavLink} to="/">
            <BrandIcon />
            Workspace
          </Brand>
          <NavbarLanguagesDropdown />
        </Toolbar>
      </AppBar>
      <Wrapper>
        <Suspense fallback={<CircularProgress />}>
          <Routes>
            <Route
              path="/"
              element={
                <OrganizationProfile
                  form_data={form_data}
                  setFormData={setFormData}
                />
              }
            />
            <Route
              path="/admin-profile"
              element={
                <AdminProfile form_data={form_data} setFormData={setFormData} />
              }
            />
            <Route
              path="/domain"
              element={
                <OrganizationDomain
                  form_data={form_data}
                  setFormData={setFormData}
                />
              }
            />
            <Route
              path="/username"
              element={
                <UserName form_data={form_data} setFormData={setFormData} />
              }
            />
            <Route
              path="/review"
              element={
                <Review form_data={form_data} setFormData={setFormData} />
              }
            />
            <Route
              path="/success"
              element={
                <Success form_data={form_data} setFormData={setFormData} />
              }
            />
          </Routes>
        </Suspense>
      </Wrapper>
    </>
  );
};

export default SignUp;
